export const FAVOURITES_LIST_TO_ADD = ['main_email_form', 'test_form', 'asutp_smax_enterprise_form', 'asutp_smax_form'];
export const SELECT_VALUE_ALL = 'All';
export const NOT_IN_STORAGE = 'not in storage';
export const MAIN_PAGE= 'MainPage';
export const NOTIFICATIONS_PAGE= 'NotificationsPage';

export const  STATUS_CODE = {
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  TooManyRequests: 429,
  InternalServerError: 500,
  Success: 200,
};

export const NotificationType = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

export const STORIES_SWIPE = {
  LEFT: 'left_direction',
  RIGHT: 'right_direction',
  UP: 'up_direction',
  DOWN: 'down_direction',
};

export const LANG = {
  RU: 'ru',
  KK: 'kk',
};

export const COMPONENTS_ACTION = {
  OPEN_FILE: 'open_file',
  OPEN_URL: 'open_url',
  SHARE_LINK: 'share_link',
  SUBMIT: 'submit_form',
  NAVIGATE: 'app_navigate',
  INCLUDE: 'included_form',
  AUTH: 'auth_submit',
  QR: 'qr_submit',
  OPEN_PDF: 'open_pdf',
  SIGUR: 'sigur_ble',
};